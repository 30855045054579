<template>
  <v-container fluid>
    <TableViewComponent
      title="Table motifs temps de travail <24h"
      @addItemEvent="onAddItem()"
      addItemLabel="ajouter un motif de temps de travail"
      itemLabel="motif de temps de travail"
      itemsLabel="motifs de temps de travail"
      :items="worksDurationReason"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer ce motif de temps de travail ?"
      :loading="loading"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>



<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import WorkDurationReasonService from "@/service/dictionary/work_duration_reason_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";

export default {
  name: "TableWorksDurationReason",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**le service d'accès au APIs. */
      worksDurationReasonService: null,

      /**la liste des motifs de temps de ravail */
      worksDurationReason: [],
    };
  },
  methods: {
    onAddItem() {
      this.$router.push(routes.worksDurationReason.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.worksDurationReason = [];

        let worksDurationReason = await this.worksDurationReasonService.getAllWorkDurationReasons();

        worksDurationReason.forEach((w) => {
          let reason = JSON.parse(JSON.stringify(w));

          //action de consultation
          reason.view = () => {
            this.$router.push(routes.worksDurationReason.view.root + "/" + reason.id);
          };

          // action de modification
          reason.edit = () => {
            this.$router.push(routes.worksDurationReason.edit.root + "/" + reason.id);
          };

          //action de suppression
          reason.delete = async () => {
            try {
              await this.worksDurationReasonService.delete(reason.id);
              this.load();
            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "suppression du motif de temps de travail : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };

          this.worksDurationReason.push(reason);
        });

        // console.log(JSON.stringify(this.functions, null, 4));
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Nom",
          align: "start",
          sortable: true,
          value: "label",
          default: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditDictionary];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditDictionary, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadDictionary];
    },
  },
  mounted() {
    // Instanciation de la classe service
    this.worksDurationReasonService = new WorkDurationReasonService(this.$api);
    // Chargement des datas
    this.load();
  },
};
</script>

<style>
</style>